<template>
  <div class="check-button-container">
    <el-button
      type="info"
      plain
      :disabled="!loginValue"
      @click="handleLoginCheck"
    >
      {{ $t('accountManagement.LoginCheck') }}
    </el-button>
  </div>
</template>

<script>
import { getIsLoginDuplicate } from '@/api/member'

export default {
  name: 'CheckAccountDuplicateButton',
  props: {
    loginValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    async fetchLoginCheck(value) {
      try {
        const res = await getIsLoginDuplicate(value)
        const isDuplicate = res.data
        const message = isDuplicate ? 'isDuplicate' : 'notDuplicate'
        this.$confirm(this.$t(`accountManagement.${message}`), '', {
          type: isDuplicate ? 'error' : 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
      } catch (error) {
        console.error('getIsLoginDuplicate-error: ', error)
      }
    },
    handleLoginCheck() {
      this.fetchLoginCheck(this.loginValue)
      this.$emit('isCheckDuplicate', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.check-button-container{
  position: absolute;
  right: 0px;
  bottom: 0px;
}
</style>
